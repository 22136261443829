<!-- Page Header Start -->
<div class="container-fluid bg-404Photo1 headerPageTest1">
    <div class="container">
        <div class="d-flex flex-column align-items-center justify-content-center" style="min-height: 400px">
            <h3 class="display-3 text-white text-uppercase">404 (Página no encontrada)</h3>
            <div class="d-inline-flex text-white">
                <p class="m-0 text-uppercase"><a class="text-white" href="/">Inicio</a></p>
                <i class="fa fa-angle-double-right pt-1 px-3"></i>
                <p class="m-0 text-uppercase">404 (Página no encontrada)</p>
            </div>
        </div>
    </div>
</div>
<!-- Page Header End -->

<div class="container-fluid pt-5">
    <div class="container">
        <h1>¡Ups! Página no encontrada</h1>

        <p>Parece que la página que estás buscando no existe o que algo salió mal.</p>

        <p>Tal vez el enlace esté roto, la página fue movida o quizás haya un error temporal en nuestro sitio. ¡No te preocupes! Aquí tienes algunas opciones para continuar:</p>
        <ul>
            <li>Revisa que la URL sea correcta.</li>
            <li>Regresa a la <a href="/">página principal</a> para seguir navegando.</li>
            <li>Si necesitas ayuda, <a href="/contacto">contáctanos</a> y estaremos encantados de asistirte.</li>
        </ul>

        <p>Gracias por tu paciencia y comprensión. ¡Esperamos que encuentres lo que buscas!</p>

    </div>
</div>