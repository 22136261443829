import { Component } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http'; // Importa HttpClient y HttpClientModule
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-contacto',
  standalone: true,
  imports: [FormsModule, HttpClientModule], // Agrega HttpClientModule aquí
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css']
})
export class ContactoComponent {
  contactData = {
    name: '',
    email: '',
    subject: '',
    message: ''
  };

  constructor(private http: HttpClient) {}

  onSubmit(): void {
    const formUrl = 'https://formspree.io/f/movqwjdg'; // Coloca aquí tu URL de Formspree
    this.http.post(formUrl, this.contactData).subscribe(
      (response) => {
        alert('Mensaje enviado con éxito');
      },
      (error) => {
        alert('Hubo un error al enviar el mensaje');
      }
    );
  }
}
