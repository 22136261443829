<!-- Page Header Start -->
<div class="container-fluid bg-cookiesPhoto1 headerPageTest1">
    <div class="container">
        <div class="d-flex flex-column align-items-center justify-content-center" style="min-height: 400px">
            <h3 class="display-3 text-white text-uppercase">Política de Cookies</h3>
            <div class="d-inline-flex text-white">
                <p class="m-0 text-uppercase"><a class="text-white" href="/">Inicio</a></p>
                <i class="fa fa-angle-double-right pt-1 px-3"></i>
                <p class="m-0 text-uppercase">Política de Cookies</p>
            </div>
        </div>
    </div>
</div>
<!-- Page Header End -->

<div class="container-fluid pt-5">
    <div class="container">
        <h1>Política de Cookies</h1>

<p>En la página web de <strong>Rodríguez Rovira Advocats</strong> utilizamos cookies y tecnologías similares para mejorar la experiencia de nuestros usuarios y optimizar el funcionamiento de nuestro sitio web. Al continuar navegando, el Usuario acepta el uso de cookies bajo las condiciones establecidas en esta Política.</p>

<h2>1. ¿Qué son las cookies?</h2>
<p>Las cookies son archivos de texto de pequeño tamaño que se almacenan en el dispositivo del Usuario al visitar una página web. Su función principal es recordar la actividad del Usuario en el sitio para ofrecer una navegación más personalizada. Algunas cookies son esenciales para el funcionamiento del sitio, mientras que otras permiten mejorar el rendimiento y la experiencia del Usuario.</p>

<h2>2. Tipos de Cookies que Utilizamos</h2>
<p>En <strong>Rodríguez Rovira Advocats</strong> utilizamos los siguientes tipos de cookies:</p>
<ul>
    <li><strong>Cookies Técnicas:</strong> Son esenciales para el correcto funcionamiento del sitio web. Permiten al Usuario navegar por la Web y utilizar sus diferentes funcionalidades, como acceder a áreas seguras.</li>
    <li><strong>Cookies de Preferencias o Personalización:</strong> Permiten recordar la configuración del Usuario, como su idioma preferido o la región desde la que accede, para mejorar su experiencia.</li>
    <li><strong>Cookies de Análisis:</strong> Nos ayudan a analizar el uso que los Usuarios hacen de la Web y a evaluar su rendimiento. Utilizamos esta información para mejorar la Web y adaptarla a las necesidades de los Usuarios.</li>
    <li><strong>Cookies Publicitarias:</strong> Sirven para gestionar los espacios publicitarios de la Web, mostrando anuncios relevantes para el Usuario, en función de sus intereses y comportamiento en la Web.</li>
</ul>

<h2>3. Cookies de Terceros</h2>
<p>Algunas cookies son gestionadas por terceros, como Google Analytics, para recopilar datos estadísticos sobre el uso del sitio web. Estas cookies son instaladas por terceros y se rigen por sus respectivas políticas de privacidad.</p>

<h2>4. Gestión de Cookies</h2>
<p>El Usuario puede configurar su navegador para aceptar, rechazar o eliminar cookies. A continuación se incluyen enlaces a la configuración de los principales navegadores para que el Usuario pueda gestionar sus preferencias:</p>
<ul>
    <li><a href="https://support.google.com/chrome/answer/95647" target="_blank">Google Chrome</a></li>
    <li><a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias" target="_blank">Mozilla Firefox</a></li>
    <li><a href="https://support.apple.com/es-es/guide/safari/sfri11471/mac" target="_blank">Safari</a></li>
    <li><a href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank">Internet Explorer</a></li>
    <li><a href="https://support.microsoft.com/es-es/help/4468242/microsoft-edge-browsing-data-and-privacy" target="_blank">Microsoft Edge</a></li>
</ul>
<p>El Usuario debe tener en cuenta que desactivar las cookies puede afectar al funcionamiento y rendimiento de algunas funcionalidades de la Web.</p>

<h2>5. Actualización de la Política de Cookies</h2>
<p><strong>Rodríguez Rovira Advocats</strong> se reserva el derecho de actualizar esta Política de Cookies en cualquier momento para reflejar cambios en el uso de cookies en nuestro sitio web. Se recomienda al Usuario revisar esta Política periódicamente para estar informado sobre cómo utilizamos las cookies.</p>

<h2>6. Contacto</h2>
<p>Si el Usuario tiene alguna pregunta sobre esta Política de Cookies, puede ponerse en contacto con nosotros a través de:</p>
<ul>
    <li><strong>Correo electrónico:</strong> toni.rodriguez&#64;icasbd.org</li>
    <li><strong>Teléfono:</strong> +34 689 02 44 10</li>
</ul>

    </div>
</div>