<!-- Page Header Start -->
<div class="container-fluid bg-page-header headerPageTest1">
    <div class="container">
        <div class="d-flex flex-column align-items-center justify-content-center" style="min-height: 400px">
            <h3 class="display-3 text-white text-uppercase">Áreas</h3>
            <div class="d-inline-flex text-white">
                <p class="m-0 text-uppercase"><a class="text-white" href="/">Inicio</a></p>
                <i class="fa fa-angle-double-right pt-1 px-3"></i>
                <p class="m-0 text-uppercase">Áreas</p>
            </div>
        </div>
    </div>
</div>
<!-- Page Header End -->

<div class="container-fluid pt-5">
    <div class="container mt-5">
        <div class="row">
            <!-- Columna del Acordeón -->
            <div class="col-md-4">
                <h2>Áreas de Servicios</h2>
                <div class="accordionServicios mb-4" id="accordionExample">
                    <div class="card mb-2">
                        <div class="card-headerServicios" id="headingInmobiliario">
                            <h5 class="mb-0">
                                <a class="btn btn-link btnColorServicios collapsed" type="button" data-toggle="collapse"
                                    data-target="#collapseInmobiliario" aria-expanded="false"
                                    aria-controls="collapseInmobiliario">
                                    Civil - Inmobiliario
                                </a>
                            </h5>
                        </div>
                    </div>
                    <div class="card mb-2">
                        <div class="card-headerServicios" id="headingFamilia">
                            <h5 class="mb-0">
                                <a class="btn btn-link btnColorServicios collapsed"
                                    style="text-align: start !important;" type="button" data-toggle="collapse"
                                    data-target="#collapseFamilia" aria-expanded="false"
                                    aria-controls="collapseFamilia">
                                    Civil - Familia 
                                </a>
                            </h5>
                        </div>
                    </div>
                    <div class="card mb-2">
                        <div class="card-headerServicios" id="headingDespidos">
                            <h5 class="mb-0">
                                <a class="btn btn-link btnColorServicios collapsed" type="button" data-toggle="collapse"
                                    data-target="#collapseDespidos" aria-expanded="false"
                                    aria-controls="collapseDespidos">
                                    Laboral - Despidos
                                </a>
                            </h5>
                        </div>
                    </div>

                    <div class="card mb-2">
                        <div class="card-headerServicios" id="headingAccidentesL">
                            <h5 class="mb-0">
                                <a class="btn btn-link btnColorServicios collapsed" type="button" data-toggle="collapse"
                                    data-target="#collapseAccidentes" aria-expanded="false"
                                    aria-controls="collapseAccidentes">
                                    Laboral - Seguridad Social
                                </a>
                            </h5>
                        </div>
                    </div>

                    <div class="card mb-2">
                        <div class="card-headerServicios" id="headingAccidentesT">
                            <h5 class="mb-0">
                                <a class="btn btn-link btnColorServicios collapsed" type="button" data-toggle="collapse"
                                    data-target="#collapseAccidentesTrafico" aria-expanded="false"
                                    aria-controls="collapseAccidentesTrafico">
                                    Accidentes de tráfico
                                </a>
                            </h5>
                        </div>
                    </div>

                    <div class="card mb-2">
                        <div class="card-headerServicios" id="headingIncapacidad">
                            <h5 class="mb-0">
                                <a class="btn btn-link btnColorServicios collapsed" type="button" data-toggle="collapse"
                                    data-target="#collapseIncapacidad" aria-expanded="false"
                                    aria-controls="collapseIncapacidad">
                                    Nacionalidad y extranjería
                                </a>
                            </h5>
                        </div>
                    </div>
                    
                    
                    
                    
                </div>

                <!--
                <h2>Presupuestos y Facturas</h2>
                <p>Consulta nuestro presupuesto de servicios legales de 2024 para obtener una guía clara y detallada sobre todos los servicios que ofrecemos y sus tarifas.</p>
                <div class="accordionServicios" id="accordionExample">
                    <div class="card mb-2">
                        <div class="card-headerServicios" id="headingDespidos">
                            <h5 class="mb-0">
                                <a class="btn btn-link btnColorServicios collapsed" type="button">
                                    <i class="fa fas fa-download"></i> Descargar Factura 
                                </a>
                            </h5>
                        </div>
                    </div>
                    <div class="card mb-2">
                        <div class="card-headerServicios" id="headingIncapacidad">
                            <h5 class="mb-0">
                                <a class="btn btn-link btnColorServicios collapsed" type="button">
                                    <i class="fa fas fa-file-pdf"></i> Detalles de los Servicios
                                </a>
                            </h5>
                        </div>
                    </div>
                </div>-->

            </div>


            <!-- Columna de Contenido -->
            <div class="col-md-8">
                <div class="content-area">
                    <div id="collapseInmobiliario" class="collapse" aria-labelledby="headingInmobiliario">
                        <div class="card-body">
                            <section id="inmobiliario">
                                <h3>Civil - Inmobiliario</h3>
                                <ul>
                                    <li>
                                        <p>Comunidades de propietarios</p>
                                    </li>
                                    <p>
                                        La <strong>gestión y administración de las comunidades de propietarios</strong> que se rigen por la 
                                        <i>Ley de Propiedad Horizontal</i> puede generar conflictos que requieran de intervención legal y judicial. 
                                        Nuestro despacho se especializa en ofrecer un <strong>servicio integral de asesoría y gestión</strong> a las 
                                        <i>administraciones de fincas</i> y a las <strong>comunidades de propietarios</strong>.
                                    </p>
                                    <p>
                                        Desde <strong>Rodríguez Rovira Advocats</strong> nos encargamos de <strong>reclamar las deudas de los propietarios morosos</strong> 
                                        tanto por la <i>vía judicial</i> como por la <i>extrajudicial</i>, elaborando las demandas necesarias y representando a nuestros clientes. 
                                        Nuestra <strong>experiencia en este ámbito</strong> nos permite ofrecer un servicio ágil, adecuado y efectivo que garantiza la 
                                        <strong>protección y defensa de los intereses</strong> de la comunidad y de los propietarios al corriente de pago, y el 
                                        <strong>funcionamiento adecuado de las administraciones de fincas</strong>.
                                    </p>
                                    <li>
                                        <p>Gestión de patrimonio y de los alquileres</p>
                                    </li>
                                    <p>
                                        En el ámbito de la <strong>gestión patrimonial</strong> ofrecemos una <strong>asesoría legal</strong> en 
                                        <i>contratos de alquiler</i>, <i>desahucios</i> y cualquier aspecto relacionado con la <strong>gestión de los inmuebles</strong> 
                                        de nuestros clientes. 
                                    </p>
                                    <p>
                                        En este sentido, ayudamos tanto a <strong>propietarios</strong> como a <strong>inquilinos</strong> a defender sus 
                                        <strong>derechos y obligaciones</strong> y buscamos <strong>soluciones</strong> que protejan sus intereses.
                                    </p>
                                </ul>
                            </section>
                        </div>
                    </div>

                    <div id="collapseFamilia" class="collapse" aria-labelledby="headingFamilia">
                        <div class="card-body">
                            <section id="familia">
                                <h3>Civil - Familia</h3>
                                <p>
                                    En nuestro despacho, el servicio que ofrecemos en el ámbito del <strong>derecho familiar</strong> se basa en entender cada situación 
                                    de manera <strong>única y específica</strong>, acompañando a nuestros clientes en la <i>mediación familiar</i>, buscando resolver 
                                    sus conflictos de una manera <strong>menos conflictiva, pacífica y eficiente</strong>.
                                </p>
                                <p>
                                    Nuestro enfoque se basa en la <strong>empatía</strong> y la <strong>comprensión de las situaciones personales y familiares</strong> 
                                    de nuestros clientes, asegurando que se sientan apoyados mientras trabajamos para ofrecerles 
                                    <strong>soluciones personalizadas</strong> que se ajusten a sus necesidades.
                                </p>
                                
                                <ul>
                                    <li>
                                        <p>La mediación en conflictos familiares</p>
                                    </li>
                                    <li>
                                        <p>Asesoría en acuerdos de separación o divorcio</p>
                                    </li>
                                    <li>
                                        <p>Apoyo en situaciones de incapacidad o tutela</p>
                                    </li>
                                    <li>
                                        <p>Gestión y asesoramiento en herencias familiares</p>
                                    </li>
                                    <li>
                                        <p>Apoyo para establecer acuerdos personalizados</p>
                                    </li>
                                </ul>
                            </section>
                        </div>
                    </div>

                    <div id="collapseDespidos" class="collapse" aria-labelledby="headingDespidos">
                        <div class="card-body">
                            <section id="despidos">
                                <h3>Laboral - Despidos</h3>
                                <p>
                                    El <strong>despido</strong> en el ámbito del <strong>derecho laboral</strong> es una de las cuestiones más recurrentes y 
                                    más complejas de gestionar tanto para los <strong>empleados</strong> como para los <strong>empleadores</strong>. 
                                    Nuestro despacho se enorgullece de ofrecer un <strong>asesoramiento</strong> basado en la <strong>confianza</strong>, 
                                    la <i>cercanía</i> y la <strong>profesionalidad</strong>.
                                </p>
                                <p>
                                    En <strong>Rodríguez Rovira Advocats</strong> todo <strong>asesoramiento</strong> que realizamos es con el ánimo de 
                                    defender a nuestros clientes y velar por sus <strong>intereses</strong>.
                                </p>

                                <ul>
                                    <li><p>Bajas médicas
                                        prolongadas</p></li>
                                    <li><p>Reducción de
                                        plantilla en la empresa</p></li>
                                    <li><p>Edad o
                                        antigüedad</p></li>
                                    <li><p>Presentar una
                                        reclamación</p></li>
                                    <li><p>Sin previo
                                        aviso o sin justificación clara</p></li>
                                </ul>
                                
                            </section>
                        </div>
                    </div>

                    <div id="collapseAccidentes" class="collapse" aria-labelledby="headingAccidentesL">
                        <div class="card-body">
                            <section id="seguridad-social">
                                <h3>Laboral - Seguridad Social</h3>
                                <p>
                                    En nuestro despacho ofrecemos <strong>asesoramiento integral</strong> sobre los <strong>derechos y prestaciones de los trabajadores</strong>, 
                                    especialmente en lo relativo a la <strong>incapacidad</strong>, ya sea <i>temporal</i> o <i>permanente</i>, y la <strong>jubilación</strong>, 
                                    ofreciendo un <strong>acompañamiento legal</strong> en el proceso de solicitud de prestaciones, asegurando que se cumplan todos los 
                                    <strong>requisitos legales</strong>.
                                </p>
                                <p>
                                    En cuanto al ámbito de los <strong>accidentes laborales</strong>, nuestro equipo se ocupa de realizar un 
                                    <strong>análisis detallado</strong> de cada situación para determinar la <strong>responsabilidad</strong> y las posibles vías de 
                                    <i>reclamación</i>. A este respecto, asesoramos a nuestros clientes en la <strong>gestión de trámites</strong> relacionados con 
                                    la <i>seguridad social</i>, asegurando que los mismos reciban las <strong>prestaciones adecuadas</strong> a cada caso concreto.
                                </p>
                                
                                <ul>
                                    <li><p>Asesoría para reclamar tus prestaciones</p></li>
                                    <li><p>Ayuda para gestionar los trámites con la seguridad social</p></li>
                                    <li><p>Orientación para determinar responsabilidades</p></li>
                                    <li><p>Apoyo para recibir una compensación justa</p></li>
                                    <li><p>Acompañamiento legal durante el proceso de reclamación</p></li>
                                </ul>
                            </section>
                        </div>
                    </div>

                    <div id="collapseAccidentesTrafico" class="collapse" aria-labelledby="headingAccidentesT">
                        <div class="card-body">
                            <section id="accidentes-trafico">
                                <h3>Accidentes de tráfico</h3>
                                <p>
                                    En nuestro despacho brindamos un <strong>servicio integral de asistencia legal</strong> a las <strong>víctimas de accidentes de tráfico</strong>, 
                                    ayudándolas a obtener las <strong>compensaciones legales</strong> que les correspondan. En cada caso realizamos una 
                                    <strong>evaluación exhaustiva</strong> de la <i>responsabilidad</i> y los <i>daños sufridos</i>, asesorando a nuestros clientes 
                                    y guiándolos para <strong>defender sus intereses</strong>.
                                </p>
                                <p>
                                    Nuestro compromiso es brindar <strong>apoyo a estas víctimas</strong>, garantizando que reciban la mayor 
                                    <strong>compensación económica posible</strong> y necesaria para restablecer su <strong>estabilidad y bienestar</strong>.
                                </p>
                                
                                <ul>
                                    <li><p>Asesoría para obtener tu compensación legal</p></li>
                                    <li><p>Ayuda para evaluar la responsabilidad en el accidente</p></li>
                                    <li><p>Orientación para reclamar los daños sufridos</p></li>
                                    <li><p>Apoyo para defender tus intereses como víctima</p></li>
                                    <li><p>Acompañamiento para asegurar tu estabilidad y bienestar</p></li>
                                </ul>
                            </section>
                        </div>
                    </div>

                    <div id="collapseIncapacidad" class="collapse" aria-labelledby="headingIncapacidad">
                        <div class="card-body">
                            <section id="incapacidad">
                                <h3>Nacionalidad y extranjería</h3>
                                <p>
                                    En <strong>Rodríguez Rovira Advocats</strong> ofrecemos <strong>asesoría jurídica especializada</strong> en todos los aspectos relacionados con la 
                                    <strong>nacionalidad</strong> y la <strong>extranjería</strong>, ayudando a nuestros clientes a <strong>regularizar su situación</strong> y a obtener los 
                                    <strong>derechos</strong> que les corresponden. Asimismo, también los ayudamos a obtener la <strong>renovación de su permiso de residencia</strong> 
                                    o la <strong>nacionalidad española</strong> y a resolver cualquier duda relacionada con su <i>estatus migratorio</i>.
                                </p>
                                <p>
                                    En todo caso, acompañamos a nuestros clientes en todo el proceso y nos aseguramos de que sus solicitudes cumplan con todos los 
                                    <strong>requisitos legales</strong>. Nuestro objetivo es proporcionar <strong>apoyo</strong> a quienes necesitan <strong>regularizar su estatus</strong> en el país, 
                                    garantizando una <strong>gestión eficiente y segura</strong> de todos los <i>trámites relacionados con la extranjería</i>.
                                </p>
                                
                                <ul>
                                    <li><p>Solicitud y renovación del permiso de residencia</p></li>
                                    <li><p>Gestión de la nacionalidad española</p></li>
                                    <li><p>Asesoramiento en la reagrupación familiar</p></li>
                                    <li><p>Resolución de dudas sobre tu estatus migratorio</p></li>
                                    <li><p>Tramitación de recursos por denegación de permisos</p></li>
                                </ul>
                            </section>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <!--
        <div class="row">
            <div class="col-md-4 column">
                <div class="content-area">
                    <h4>Columna Adicional 1</h4>
                    <p>Contenido para la columna adicional 1. Este contenido se ajustará debajo de la columna de
                        contenido principal cuando la pantalla sea pequeña.</p>
                </div>
            </div>
            <div class="col-md-4 column">
                <div class="content-area">
                    <h4>Columna Adicional 2</h4>
                    <p>Contenido para la columna adicional 2. Puedes añadir más información o funcionalidades aquí.</p>
                </div>
            </div>
            <div class="col-md-4 column">
                <div class="content-area">
                    <h4>Columna Adicional 3</h4>
                    <p>Contenido para la columna adicional 3. Esta sección también se ajustará a la responsividad del
                        diseño.</p>
                </div>
            </div>
        </div>-->
    </div>
</div>