<!-- Page Header Start -->
<div class="container-fluid bg-privacidadPhoto1 headerPageTest1">
    <div class="container">
        <div class="d-flex flex-column align-items-center justify-content-center" style="min-height: 400px">
            <h3 class="display-3 text-white text-uppercase">Política de Privacidad</h3>
            <div class="d-inline-flex text-white">
                <p class="m-0 text-uppercase"><a class="text-white" href="/">Inicio</a></p>
                <i class="fa fa-angle-double-right pt-1 px-3"></i>
                <p class="m-0 text-uppercase">Política de Privacidad</p>
            </div>
        </div>
    </div>
</div>
<!-- Page Header End -->

<div class="container-fluid pt-5">
    <div class="container">
        <h1>Política de Privacidad</h1>

        <p>En <strong>Rodríguez Rovira Advocats</strong> estamos comprometidos con la protección de la privacidad y los
            datos personales de nuestros usuarios. Esta Política de Privacidad describe cómo recopilamos, usamos y
            protegemos su información en cumplimiento con el <i>Reglamento (UE) 2016/679</i> (Reglamento General de
            Protección de Datos - RGPD) y demás normativa aplicable.</p>

        <h2>1. Identificación del Responsable</h2>
        <p>El responsable del tratamiento de los datos personales es:</p>
        <ul>
            <li><strong>Rodríguez Rovira Advocats</strong></li>
            <!--<li><strong>CIF:</strong> [Número de CIF]</li>-->
            <li><strong>Domicilio:</strong> Carrer de l'Escola Pia, 7, 08201 Sabadell, Barcelona</li>
            <li><strong>Correo electrónico:</strong> toni.rodriguez&#64;icasbd.org</li>
            <li><strong>Teléfono:</strong> +34 689 02 44 10</li>
        </ul>

        <h2>2. Datos Personales Recopilados</h2>
        <p>Podemos recopilar y procesar los siguientes datos personales:</p>
        <ul>
            <li><strong>Datos de contacto:</strong> Nombre, dirección de correo electrónico, número de teléfono, etc.
            </li>
            <li><strong>Datos de navegación:</strong> Información sobre cómo utiliza nuestra Web, dirección IP,
                navegador, e información relacionada con cookies (ver <a href="/cookies">Política de Cookies</a> para
                más detalles).</li>
                <li><strong>Datos consulta online:</strong> Información relacionada con las reservas de citas online, incluyendo fecha y hora seleccionadas, e historial de consultas realizadas.</li>
                <li><strong>Datos consulta online pago:</strong> Datos relacionados con el pago (sin incluir información confidencial como números completos de tarjetas de crédito).</li>
            <li><strong>Otros datos:</strong> Cualquier otra información que el Usuario nos facilite a través de
                formularios o consultas enviadas por los medios de contacto en la Web.</li>
        </ul>

        <h2>3. Finalidades del Tratamiento de Datos</h2>
        <p>Los datos personales recopilados serán tratados para las siguientes finalidades:</p>
        <ul>
            <li>Responder a consultas y solicitudes de información enviadas por el Usuario.</li>
            <li>Gestionar la relación contractual o precontractual en caso de que el Usuario contrate nuestros
                servicios.</li>
            <li>Enviar comunicaciones comerciales o informativas, siempre que el Usuario haya dado su consentimiento.
            </li>
            <li>Gestionar el proceso de reserva de citas online y los pagos asociados.</li>
            <li>Enviar recordatorios y confirmaciones relacionadas con las consultas online.</li>
            <li>Analizar el uso de la Web para mejorar su funcionamiento y la experiencia del Usuario.</li>
        </ul>

        <h2>4. Base Legal para el Tratamiento</h2>
        <p>El tratamiento de los datos del Usuario se basa en las siguientes bases legales:</p>
        <ul>
            <li><strong>Consentimiento del Usuario</strong>: Para el envío de comunicaciones relacionadas con el servicio, cuando sea necesario.</li>
            <li><strong>Interés legítimo</strong>: Para atender a las consultas del Usuario y para mejorar nuestros
                servicios.</li>
            <li><strong>Ejecución de un contrato</strong>: Para la correcta gestión de las reservas y prestación del servicio solicitado.</li>
        </ul>

        <h2>5. Conservación de los Datos</h2>
        <p>Los datos personales serán conservados durante el tiempo necesario para cumplir con las finalidades para las
            que fueron recopilados, y en cualquier caso, hasta que el Usuario solicite su supresión o revoque su
            consentimiento. En algunos casos, podremos conservar los datos por un período más largo para el cumplimiento
            de obligaciones legales.</p>

        <h2>6. Derechos del Usuario</h2>
        <p>El Usuario tiene derecho a:</p>
        <ul>
            <li><strong>Acceder</strong> a sus datos personales.</li>
            <li><strong>Rectificar</strong> los datos inexactos o incompletos.</li>
            <li><strong>Solicitar la supresión</strong> de sus datos cuando, entre otros motivos, ya no sean necesarios
                para los fines que fueron recogidos.</li>
            <li><strong>Solicitar la limitación</strong> del tratamiento de sus datos en determinadas circunstancias.
            </li>
            <li><strong>Oponerse</strong> al tratamiento de sus datos personales en determinados supuestos.</li>
            <li><strong>Solicitar la portabilidad</strong> de sus datos en un formato estructurado, de uso común y
                lectura mecánica.</li>
        </ul>
        <p>Para ejercer estos derechos, el Usuario puede contactar con nosotros a través de los medios indicados en la
            sección de contacto. Deberá aportar copia de un documento que acredite su identidad (DNI, NIE, pasaporte,
            etc.).</p>

        <h2>7. Comunicaciones de Datos a Terceros</h2>
        <p>Los datos personales del Usuario no se comunicarán a terceros, salvo a los proveedores de servicios estrictamente necesarios para la gestión de las consultas online, como plataformas de videoconferencia (Microsoft Teams) y servicios de pago. Estos proveedores están sujetos a estrictas obligaciones de confidencialidad.</p>

        <h2>8. Seguridad de los Datos</h2>
        <p>En el caso de los pagos en línea, se garantiza que las transacciones se realizarán en plataformas seguras que cumplen con los estándares de seguridad internacionales. <strong>Rodríguez Rovira Advocats</strong> no almacenará información confidencial sobre medios de pago.</p>

        <h2>9. Política de Cookies</h2>
        <p>Nuestra Web utiliza cookies para mejorar la experiencia de los usuarios. Para más información sobre el uso de
            cookies, consulte nuestra <a href="/cookies">Política de Cookies</a>.</p>

        <h2>10. Cambios en la Política de Privacidad</h2>
        <p><strong>Rodríguez Rovira Advocats</strong> se reserva el derecho de modificar esta Política de Privacidad
            para adaptarla a cambios legislativos o de funcionamiento de la Web. Cualquier modificación será publicada
            en esta misma página y se le notificará al Usuario en caso de que implique un cambio significativo en el
            tratamiento de sus datos.</p>

        <h2>11. Contacto</h2>
        <p>Para cualquier consulta sobre esta Política de Privacidad o para ejercer sus derechos, el Usuario puede
            ponerse en contacto con nosotros a través de:</p>
        <ul>
            <li><strong>Correo electrónico:</strong> toni.rodriguez&#64;icasbd.org</li>
            <li><strong>Teléfono:</strong> +34 689 02 44 10</li>
        </ul>

    </div>
</div>