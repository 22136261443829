<!-- Page Header Start -->
<div class="container-fluid bg-avisoPhoto1 headerPageTest1">
    <div class="container">
        <div class="d-flex flex-column align-items-center justify-content-center" style="min-height: 400px">
            <h3 class="display-3 text-white text-uppercase">Aviso Legal</h3>
            <div class="d-inline-flex text-white">
                <p class="m-0 text-uppercase"><a class="text-white" href="/">Inicio</a></p>
                <i class="fa fa-angle-double-right pt-1 px-3"></i>
                <p class="m-0 text-uppercase">Aviso Legal</p>
            </div>
        </div>
    </div>
</div>
<!-- Page Header End -->

<div class="container-fluid pt-5">
    <div class="container">
        <h1>Aviso Legal y Condiciones de Uso</h1>

        <p>Bienvenido a la página web de <strong>Rodríguez Rovira Advocats</strong> (en adelante, la
            “<strong>Web</strong>”). Este Aviso Legal establece los términos y condiciones que regulan el acceso y uso
            de la Web. Al acceder a ella, usted (en adelante, el "<strong>Usuario</strong>") acepta plenamente y sin
            reservas estos términos. En caso de no estar de acuerdo, deberá abstenerse de utilizar la Web.</p>

        <h2>1. Identificación del Titular</h2>
        <p>La Web es titularidad de:</p>
        <ul>
            <li><strong>Rodríguez Rovira Advocats</strong></li>
            <!--<li><strong>CIF</strong>: [Número de CIF]</li>-->
            <li><strong>Domicilio</strong>: Carrer de l'Escola Pia, 7, 08201 Sabadell, Barcelona</li>
            <li><strong>Correo electrónico</strong>: toni.rodriguez&#64;icasbd.org</li>
            <li><strong>Teléfono</strong>: +34 689 02 44 10</li>
        </ul>

        <h2>2. Aceptación de las Condiciones de Uso</h2>
        <p>El acceso y uso de la Web están sujetos a este Aviso Legal, y el Usuario se compromete a utilizar la Web en
            conformidad con la ley, la moral y el orden público. <strong>Rodríguez Rovira Advocats</strong> se reserva
            el derecho a modificar, en cualquier momento y sin previo aviso, el contenido y las condiciones de uso de la
            Web, por lo que el Usuario deberá consultar periódicamente este Aviso Legal.</p>

        <h2>3. Objeto y Ámbito de Aplicación</h2>
        <p>La Web tiene como finalidad principal ofrecer información sobre los servicios legales proporcionados por <strong>Rodríguez Rovira Advocats</strong>, facilitar la reserva de citas online para consultas legales y ofrecer información sobre temas de interés jurídico. La contratación de consultas online se regirá por las condiciones específicas detalladas en la sección de reserva de citas y en los correos electrónicos de confirmación enviados al Usuario.</p>

        <h2>4. Uso Correcto de la Web</h2>
        <p>El Usuario se compromete a facilitar información veraz, actualizada y completa al realizar reservas de citas online y formular consultas. Asimismo, el Usuario deberá realizar los pagos en los plazos indicados para confirmar las reservas. El incumplimiento de estas condiciones podrá resultar en la cancelación de la reserva sin derecho a reembolso.</p>

        <h2>5. Propiedad Intelectual e Industrial</h2>
        <p>Todos los contenidos de la Web, incluyendo textos, imágenes, logotipos, gráficos y otros elementos, son
            propiedad de <strong>Rodríguez Rovira Advocats</strong> o de terceros que han autorizado su uso, estando
            protegidos por derechos de propiedad intelectual e industrial. El Usuario se compromete a respetar estos
            derechos y a no reproducir, distribuir, o modificar dichos contenidos sin la debida autorización.</p>

        <h2>6. Enlaces a Terceros</h2>
        <p>La Web puede contener enlaces a sitios de terceros para la conveniencia del Usuario. <strong>Rodríguez Rovira
                Advocats</strong> no se hace responsable del contenido, funcionamiento o disponibilidad de estos sitios,
            y la inclusión de tales enlaces no implica una relación de asociación ni aprobación de los mismos.</p>

        <h2>7. Limitación de Responsabilidad</h2>
        <p><strong>Rodríguez Rovira Advocats</strong> no se hace responsable de los posibles problemas técnicos que puedan surgir durante las consultas online realizadas mediante Microsoft Teams u otras plataformas. El Usuario es responsable de disponer de los medios técnicos necesarios y de garantizar la conexión a Internet para la realización de dichas consultas.</p>

        <h2>8. Protección de Datos Personales</h2>
        <p><strong>Rodríguez Rovira Advocats</strong> cumple con la normativa vigente en materia de protección de datos
            personales, incluyendo el Reglamento General de Protección de Datos (<i>RGPD</i>). Los datos personales
            facilitados por el Usuario serán tratados conforme a nuestra <a href="/privacidad">Política de
                Privacidad</a>, disponible en la Web.</p>

        <h2>9. Ley Aplicable y Jurisdicción</h2>
        <p>Las presentes Condiciones se rigen por la legislación española. En caso de controversias, ambas partes se
            someten, con renuncia expresa a cualquier otro fuero, a los Juzgados y Tribunales de
            <strong>Sabadell</strong>.</p>

            <h2>10. Condiciones Específicas para las Consultas Online</h2>
            <ol>
                <li>
                    Proceso de Reserva y Confirmación:
                </li>
                <ul>
                    <li>El Usuario debe seleccionar una fecha y hora disponible mediante el formulario proporcionado en la Web.</li>
                    <li>La reserva será confirmada una vez que el Usuario reciba un correo electrónico con las instrucciones de pago.</li>
                </ul>
                <li>
                    Condiciones de Pago:
                </li>
                <ul>
                    <li>El Usuario debe completar el pago mediante transferencia bancaria, PayPal o Bizum en el plazo indicado.</li>
                    <li>En caso de no recibir el pago, la reserva será cancelada automáticamente.</li>
                </ul>
                <li>
                    Política de Cancelación y Reembolsos:
                </li>
                <ul>
                    <li>El Usuario podrá cancelar la reserva antes de la fecha programada para la consulta y solicitar un reembolso.</li>
                    <li>No se realizarán reembolsos una vez que la consulta haya tenido lugar.</li>
                </ul>
            </ol>
           


        <h2>11. Contacto</h2>
        <p>Para cualquier consulta o aclaración en relación con este Aviso Legal, el Usuario puede contactarnos a través
            de:</p>
        <ul>
            <li><strong>Correo electrónico</strong>: toni.rodriguez&#64;icasbd.org</li>
            <li><strong>Teléfono</strong>: +34 689 02 44 10</li>
        </ul>
    </div>
</div>