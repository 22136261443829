<!-- Page Header Start -->
<div class="container-fluid bg-lafirmaPhoto1 headerPageTest1">
    <div class="container">
        <div class="d-flex flex-column align-items-center justify-content-center" style="min-height: 400px">
            <h3 class="display-3 text-white text-uppercase">La Firma</h3>
            <div class="d-inline-flex text-white">
                <p class="m-0 text-uppercase"><a class="text-white" href="/">Inicio</a></p>
                <i class="fa fa-angle-double-right pt-1 px-3"></i>
                <p class="m-0 text-uppercase">La Firma</p>
            </div>
        </div>
    </div>
</div>
<!-- Page Header End -->

<div class="container-fluid pt-5">
    <div class="container">
        <div class="rounded">
            <div class="d-flex align-items-center">
                <div class="icon-box bg-secondary text-primary mt-2 mb-4">
                    <i class="fa fa-2x fas fa-landmark"></i>
                </div>
                <h1 class="mb-4 ml-3">Historia</h1>
            </div>

            <div class="row mt-4">
                <div class="col-md-8">
                    
                    <p>En la ciudad de <strong>Sabadell</strong>, de reciente fundación, abre las puertas al público el
                        despacho profesional <strong>Rodríguez Rovira Advocats</strong>. Nacido como un pequeño proyecto
                        con el objetivo de brindar un <strong>servicio legal</strong> accesible y de calidad a las
                        personas, el despacho busca con el paso de los años un <strong>crecimiento</strong> y
                        <strong>evolución</strong>, expandiendo sus <strong>áreas de práctica</strong> y fortaleciendo
                        nuestro compromiso con nuestros clientes.
                    </p>
                    <p>A lo largo del tiempo, hemos enfrentado varios <strong>desafíos profesionales</strong>
                        adquiriendo por ello una importante <strong>experiencia</strong>, celebrando logros y
                        aprendiendo de los problemas que han ido suscitando a lo largo del camino. Siempre nos hemos
                        mantenido firmes con nuestros <strong>valores</strong>, velando por los <strong>derechos e
                            intereses</strong> de nuestros clientes y ofreciéndoles <strong>soluciones</strong>
                        adecuadas a sus <strong>necesidades</strong>. Hoy por hoy, nos enorgullece formar parte del
                        <strong>sector legal</strong> y apoyar tanto a <strong>particulares</strong> como
                        <strong>empresas</strong> en su lucha por la <strong>justicia</strong>.
                    </p>
                </div>
                <div class="col-md-4 text-right">
                    <img src="assets/img/historia1.JPG" class="img-fluid rounded" alt="Descripción de la imagen">

                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid pt-5">
    <div class="container">
        <div class="rounded">
            <div class="d-flex align-items-center">
                <div class="icon-box bg-secondary text-primary mt-2 mb-4">
                    <i class="fa fa-2x fas fa-balance-scale"></i>
                </div>
                <h1 class="mb-4 ml-3">Valores y Filosofía</h1>
            </div>

            <div class="row">
                <p>En <strong>Rodríguez Rovira Advocats</strong> nos preocupamos por defender los <strong>derechos e
                        intereses</strong> de nuestros clientes de una manera <strong>transparente</strong>,
                    <strong>ética</strong> y comprometida con la <strong>justicia</strong>. Creemos en la importancia de
                    construir <strong>relaciones sólidas</strong> con las personas, basándonos en la
                    <strong>confianza</strong> y el <strong>respeto mutuo</strong>. Nuestra <strong>filosofía</strong>
                    se centra en escuchar atentamente las <strong>necesidades</strong> de nuestros clientes, saber
                    exactamente cuáles son sus <strong>preocupaciones</strong> y ofrecerles <strong>soluciones
                        adaptadas</strong> a sus necesidades y circunstancias. Queremos ser un <strong>despacho de
                        abogados cercano</strong>, manteniendo los <strong>estándares de profesionalidad</strong> que se
                    exigen en nuestra profesión y trabajando para velar por los <strong>intereses y los
                        derechos</strong> de quienes confían en nosotros.
                </p>

            </div>
        </div>
    </div>
</div>

<div class="container-fluid pt-5">
    <div class="container">
        <div class="rounded">


            <div class="row mt-4">
                <div class="col-md-8">

                    <div class="d-flex align-items-center">
                        <div class="icon-box bg-secondary text-primary mt-2 mb-4">
                            <i class="fa fa-2x fas fa-user-tie"></i>
                        </div>
                        <h1 class="mb-4 ml-3">El Equipo</h1>
                    </div>
                    <p>Aportando una <strong>rica experiencia</strong> en diversas <strong>áreas legales</strong>,
                        nuestro
                        despacho tiene un <strong>enfoque colaborativo</strong>, abordando cada caso con una
                        <strong>perspectiva integral</strong> y garantizando que nuestros clientes reciban el
                        <strong>mejor
                            servicio posible</strong>. Con una perfecta mezcla de <strong>experiencia</strong>,
                        <strong>conocimientos técnicos</strong> y <strong>habilidades interpersonales</strong>, estamos
                        aquí
                        para guiar a nuestros clientes con paso firme en cada etapa de su <strong>proceso
                            legal</strong>.
                    </p>

                    <p>Apasionado por la <strong>excelencia</strong> y comprometido con el <strong>crecimiento personal
                            y
                            profesional</strong>, <strong>Toni Rodríguez</strong> lidera el despacho. Desde pequeño,
                        Toni
                        tenía claro que iba a dedicarse a la <strong>abogacía</strong> y trabajó duro para conseguirlo.
                        <strong>Graduado en Derecho</strong> por la <i>Universidad Pompeu Fabra</i>, Toni compaginó los
                        estudios con sus dos grandes pasiones, el <i>Deporte</i> y las <strong>relaciones
                            personales</strong>. Tras haber realizado el <strong>Máster de Abogacía</strong> y trabajado
                        en
                        varios <strong>despachos profesionales</strong> como pasante, abogado y liderando
                        <strong>departamentos jurídicos</strong> de empresas, Toni afronta este nuevo reto con
                        <strong>ilusión</strong> y <strong>motivación</strong>, aspirando siempre a los <strong>mejores
                            resultados</strong> y brindando un <strong>excelente servicio</strong> a sus clientes.
                    </p>
                </div>
                <div class="col-md-4 text-right">
                    <img src="assets/img/elequipo1.JPG" class="img-fluid rounded" alt="Descripción de la imagen">

                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid pt-5">
    <div class="container">
        <div class="rounded">
            <div class="d-flex align-items-center">
                <div class="icon-box bg-secondary text-primary mt-2 mb-4">
                    <i class="fa fa-2x fas fa-bullseye"></i>
                </div>
                <h1 class="mb-4 ml-3">Nuestra Misión y Objetivos</h1>
            </div>

            <div class="row">
                <p>En <strong>Rodríguez Rovira Advocats</strong>, nuestra <strong>misión</strong> es proporcionar un
                    <strong>servicio jurídico integral</strong> a nuestros clientes, de <strong>alta calidad</strong> y
                    que los <strong>empodere</strong> a tomar las <strong>mejores decisiones</strong> en relación a sus
                    asuntos. Nuestro <strong>compromiso</strong> es ofrecer <strong>soluciones efectivas</strong>,
                    <strong>personalizadas</strong> y adaptadas a las <strong>necesidades específicas</strong> de las
                    personas que nos contratan. <strong>Compromiso</strong>, <strong>cercanía</strong>,
                    <strong>profesionalidad</strong>, <strong>calidad</strong> y <strong>eficacia</strong> son algunos
                    de los aspectos que mejor definen nuestros servicios.
                </p>

                <p>En este despacho creemos que la mejor manera de ofrecer <strong>justicia</strong> a las personas es
                    brindar un <strong>servicio de confianza</strong>, <strong>accesible</strong> y
                    <strong>profesional</strong>. Confía en nosotros para acompañarte en tu <strong>proceso
                        legal</strong> y te proporcionaremos un servicio que cumpla con todos estos
                    <strong>objetivos</strong> y más.
                </p>

            </div>
        </div>
    </div>
</div>