import { Component } from '@angular/core';

@Component({
  selector: 'app-avisolegal',
  standalone: true,
  imports: [],
  templateUrl: './avisolegal.component.html',
  styleUrl: './avisolegal.component.css'
})
export class AvisolegalComponent {

}
