/*
*  Protractor support is deprecated in Angular.
*  Protractor is used in this example for compatibility with Angular documentation tools.
*/
import { bootstrapApplication,provideProtractorTestingSupport } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { provideRouter } from '@angular/router';
import routeConfig from './app/app.routes';
import { provideHttpClient, withFetch } from '@angular/common/http'; // Importa withFetch
import { appConfig } from './app/app.config';

bootstrapApplication(AppComponent,
  {
    providers: [
      appConfig.providers,
      provideProtractorTestingSupport(),
      provideRouter(routeConfig),
      provideHttpClient(withFetch())
    ]
  }
).catch(err => console.error(err));
