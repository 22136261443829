<!-- Header Start -->
<div class="container-fluid">
    <div class="row navTest1">
      <div class="col-lg-4 bg-secondary d-none d-lg-block">
        <a href="/" class="navbar-brand w-100 h-100 m-0 p-0 d-flex align-items-center justify-content-center">
          <img src="assets/img/LogoRRadvocatsWhite.png" class="navImgLogo1" />
          <div class="navTextLogo1">
            <div class="logoFont1 m-0 text-primary text-uppercase">Rodríguez</div>
            <div class="logoFont2 m-0 text-primary text-uppercase">Rovira</div>
            <div class="logoFont3 m-0 text-primary text-uppercase">Advocats</div>
          </div>

        </a>
      </div>
      <div class="col-lg-8 m-auto colorChangeTest1">
        <div class="row bg-white border-bottom d-none d-lg-flex navTest2">
          <div class="col-lg-10 text-left">
            <div class="h-100 d-inline-flex align-items-center py-2 px-3">
              <i class="fa fa-envelope text-primary mr-2"></i>
              <small>toni.rodriguez&#64;icasbd.org</small>
            </div>
            <div class="h-100 d-inline-flex align-items-center py-2 px-3">
              <i class="fa fa-phone-alt text-primary mr-2"></i>
              <small>937 48 49 48</small>
            </div>
            <div class="h-100 d-inline-flex align-items-center py-2 px-3">
              <i class="fab fa-whatsapp text-primary mr-2"></i>
              <small>689 02 44 10</small>
            </div>
          </div>
          <div class="col-lg-2 text-right">
            <div class="d-inline-flex align-items-center p-2">
              <!--<a class="btn btn-sm btn-outline-primary btn-sm-square mr-2" href="">
                              <i class="fab fa-facebook-f"></i>
                          </a>
                          <a class="btn btn-sm btn-outline-primary btn-sm-square mr-2" href="">
                              <i class="fab fa-twitter"></i>
                          </a>-->
              <a class="btn btn-sm btn-outline-primary btn-sm-square mr-2"
                href="https://www.linkedin.com/company/rodr%C3%ADguezrovira/" target="_blank">
                <i class="fab fa-linkedin-in"></i>
              </a>
              <a class="btn btn-sm btn-outline-primary btn-sm-square mr-2"
                href="https://www.google.com/maps/dir//Carrer+de+l'Escola+Pia,+7+08201+Sabadell+Barcelona/@41.5499709,2.1071553,16z/data=!4m8!4m7!1m0!1m5!1m1!1s0x12a49502f2537b89:0x61be093bc43158e1!2m2!1d2.1071553!2d41.5499709?entry=ttu&g_ep=EgoyMDI0MTAyMC4xIKXMDSoASAFQAw%3D%3D"
                target="_blank">
                <i class="fas fa-map-marker-alt"></i>
              </a>
              <!--<a class="btn btn-sm btn-outline-primary btn-sm-square mr-2" href="">
                              <i class="fab fa-instagram"></i>
                          </a>
                          <a class="btn btn-sm btn-outline-primary btn-sm-square mr-2" href="">
                              <i class="fab fa-youtube"></i>
                          </a>-->
            </div>
          </div>
        </div>
        <nav class="navbar navbar-expand-lg bg-white navbar-light p-0 colorChangeTest1">
          <a href="/" class="navbar-brand d-block d-lg-none">
            <div class="w-100 h-100 m-0 p-0 d-flex align-items-center justify-content-center">
              <img src="assets/img/LogoRRadvocatsWhite.png" width="70" height="70" class="navImgLogo2 mr-3" />

              <div>
                <div class="logoMiniFont1 m-0 p-0 text-primary text-uppercase">Rodríguez</div>
                <div class="logoMiniFont2 m-0 p-0 text-primary text-uppercase">Rovira</div>
                <div class="logoMiniFont3 m-0 text-primary text-uppercase">Advocats</div>
              </div>


            </div>

          </a>
          <button type="button" class="navbar-toggler navBtnToggler1" data-toggle="collapse"
            data-target="#navbarCollapse">
            <span class="navTrigger">
              <i></i>
              <i></i>
              <i></i>
            </span>
          </button>



          <div class="collapse navbar-collapse justify-content-between" id="navbarCollapse">
            <div class="navbar-nav mr-auto py-0">
              <a href="#inicio" class="nav-item nav-link colorTextChangeTest1" [innerHTML]="'app_id_1' | translate">Inicio</a>
              <a href="#firma" class="nav-item nav-link colorTextChangeTest1" [innerHTML]="'app_id_2' | translate">La Firma</a>
              <div class="dropdown">
                <a href="#servicios" class="nav-item nav-link dropdown-toggle colorTextChangeTest1"
                  data-toggle="dropdown">{{'app_id_3' | translate}} <i class="fa fa-caret-down"></i></a>
                <div class="dropdown-menu rounded-0 m-0">
                  <a href="/servicios/inmobiliario" class="dropdown-item">{{'app_id_4' | translate}}</a>
                  <a href="/servicios/familia" class="dropdown-item">{{'app_id_5' | translate}}</a>
                  <a href="/servicios/despidos" class="dropdown-item">{{'app_id_6' | translate}}</a>
                  <a href="/servicios/seguridad-social" class="dropdown-item">{{'app_id_7' | translate}}</a>
                  <a href="/servicios/accidentes-trafico" class="dropdown-item">{{'app_id_8' | translate}}</a>
                  <a href="/servicios/nacionalidad-extranjeria" class="dropdown-item">{{'app_id_9' | translate}}</a>
                </div>
              </div>
              <a href="/blog" class="nav-item nav-link colorTextChangeTest1">{{'app_id_10' | translate}}</a>

              <a href="/contacto" class="nav-item nav-link colorTextChangeTest1">{{'app_id_11' | translate}}</a>
              <div class="nav-item dropdown">
                <a href="#" class="nav-link dropdown-toggle colorTextChangeTest1" data-toggle="dropdown">{{'idioma_id_0' | translate}} <i
                    class="fa fa-caret-down"></i></a>
                <div class="dropdown-menu rounded-0 m-0">
                  <a href="#" class="dropdown-item" (click)="translateText('es')">{{'app_id_12' | translate}}</a>
                  <a href="#" class="dropdown-item" (click)="translateText('cat')">{{'app_id_13' | translate}}</a>
                  <!--<a href="#" class="dropdown-item" (click)="translateText('en')">{{'app_id_14' | translate}}</a>-->
                </div>
              </div>
            </div>
            <!--<a href="" class="btn btn-primary mr-3 d-none d-lg-block" style="font-family: 'Roboto', sans-serif;">PEDIR CITA</a>-->
            <a href="https://outlook.office.com/bookwithme/user/c123b1fba909492b860692f49fe1d0a8@icasbd.org?anonymous&ep=signature"
              target="_blank" class="btn btn-primary mr-3 d-none d-lg-block">{{'app_id_15' | translate}}</a>
          </div>
        </nav>
      </div>
    </div>
  </div>
<!-- Header End -->


<router-outlet></router-outlet>

<!-- Footer Start -->
<div class="container-fluid bg-secondary text-white pt-5 px-sm-3 px-md-5" style="margin-top: 90px;">
  <div class="row mt-5">
    <div class="col-lg-4">
      <div class="d-flex justify-content-lg-center p-4" style="background: rgba(256, 256, 256, .05);">
        <i class="fa fa-2x fa-map-marker-alt text-primary"></i>
        <div class="ml-3">
          <h5 class="text-white">{{'app_id_16' | translate}}</h5>
          <p class="m-0">C/ Escola Pia, 7, Local,<br />08201 Sabadell (Barcelona)</p>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="d-flex justify-content-lg-center p-4" style="background: rgba(256, 256, 256, .05);">
        <i class="fa fa-2x fa-envelope-open text-primary"></i>
        <div class="ml-3">
          <h5 class="text-white">{{'app_id_17' | translate}}</h5>
          <p class="m-0" style="transform: translateY(-25%);"><br>toni.rodriguez&#64;icasbd.org</p>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="d-flex justify-content-lg-center p-4" style="background: rgba(256, 256, 256, .05);">
        <i class="fa fa-2x fa-phone-alt text-primary"></i>
        <div class="ml-3">
          <h5 class="text-white">{{'app_id_18' | translate}}</h5>
          <p class="m-0 ml-1">937 48 49 48<br>689 02 44 10</p>
        </div>
      </div>
    </div>
  </div>
  <div class="row pt-5">
    <div class="col-lg-4 col-md-6 mb-5">
      <a href="/" class="navbar-brand d-flex align-items-center justify-content-center">
        <img src="assets/img/LogoRRadvocatsWhite.png" class="navImgLogoFooter1" />
        <div class="navTextLogoFooter1">
          <div class="logoFontFooter1 m-0 text-primary text-uppercase">Rodríguez</div>
          <div class="logoFontFooter2 m-0 text-primary text-uppercase">Rovira</div>
          <div class="logoFontFooter3 m-0 text-primary text-uppercase">Advocats</div>
        </div>
      </a>
      <p class="text-center">{{'app_id_19' | translate}}</p>
      <div class="d-flex justify-content-start mt-4">
        <!--<a class="btn btn-lg btn-outline-light btn-lg-square mr-2" href="#"><i class="fab fa-twitter"></i></a>
                <a class="btn btn-lg btn-outline-light btn-lg-square mr-2" href="#"><i class="fab fa-facebook-f"></i></a>-->
        <a class="btn btn-lg btn-outline-light btn-lg-square mr-2"
          href="https://www.linkedin.com/company/rodr%C3%ADguezrovira/" target="_blank"><i
            class="fab fa-linkedin-in"></i></a>
        <a class="btn btn-lg btn-outline-light btn-lg-square mr-2"
          href="https://www.google.com/maps/dir//Carrer+de+l'Escola+Pia,+7+08201+Sabadell+Barcelona/@41.5499709,2.1071553,16z/data=!4m8!4m7!1m0!1m5!1m1!1s0x12a49502f2537b89:0x61be093bc43158e1!2m2!1d2.1071553!2d41.5499709?entry=ttu&g_ep=EgoyMDI0MTAyMC4xIKXMDSoASAFQAw%3D%3D"
          target="_blank"><i class="fas fa-map-marker-alt"></i></a>
        <!--<a class="btn btn-lg btn-outline-light btn-lg-square" href="#"><i class="fab fa-instagram"></i></a>-->
      </div>
    </div>
    <div class="col-lg-4 col-md-6 mb-5 text-center">
      <h4 class="font-weight-semi-bold text-primary mb-4">{{'app_id_20' | translate}}</h4>
      <div class="d-flex flex-column justify-content-start">
        <a class="text-white mb-2" href="/"><i class="fa fa-angle-right mr-2"></i>{{'app_id_21' | translate}}</a>
        <a class="text-white mb-2" href="/firma"><i class="fa fa-angle-right mr-2"></i>{{'app_id_22' | translate}}</a>
        <a class="text-white mb-2" href="/servicios"><i class="fa fa-angle-right mr-2"></i>{{'app_id_23' | translate}}</a>
        <a class="text-white mb-2" href="/blog"><i class="fa fa-angle-right mr-2"></i>{{'app_id_24' | translate}}</a>
        <a class="text-white" href="/contacto"><i class="fa fa-angle-right mr-2"></i>{{'app_id_25' | translate}}</a>
      </div>
    </div>
    <div class="col-lg-4 col-md-6 mb-5 text-center">
      <h4 class="font-weight-semi-bold text-primary mb-4">{{'app_id_26' | translate}}</h4>
      <div class="d-flex flex-column justify-content-start">
        <a class="text-white mb-2" href="/aviso-legal"><i class="fa fa-angle-right mr-2"></i>{{'app_id_27' | translate}}</a>
        <a class="text-white mb-2" href="/privacidad"><i class="fa fa-angle-right mr-2"></i>{{'app_id_28' | translate}}</a>
        <a class="text-white mb-2" href="/cookies"><i class="fa fa-angle-right mr-2"></i>{{'app_id_29' | translate}}</a>
      </div>
    </div>
    <div class="col-lg-3 col-md-6 mb-5 d-none">
      <h4 class="font-weight-semi-bold text-primary mb-4">Suscríbete</h4>
      <p class="text-left">Al suscribirte, aceptas recibir correos electrónicos con las últimas noticias, información
        relevante y
        actualizaciones.</p>
      <div class="w-100">
        <div class="input-group">
          <input type="email" class="form-control border-0" style="padding: 25px;"
            placeholder="Tu Correo Electrónico">
          <div class="input-group-append">
            <button class="btn btn-primary px-4" disabled>Suscribirse</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row p-4 mt-5 mx-0" style="background: rgba(256, 256, 256, .05);">
    <div class="col-md-6 text-center text-md-left mb-3 mb-md-0">
      <p class="m-0 text-white" style="text-align: start !important;">Copyright &copy; 2024 <a
          class="font-weight-bold" href="/">Rodríguez Rovira Advocats</a>. {{'app_id_30' | translate}}</p>
    </div>
    <div class="col-md-6 text-center text-md-right">
      <p class="m-0 text-white" style="text-align: end !important;">{{'app_id_31' | translate}} <a class="font-weight-bold"
          href="/">HTML Codex</a></p>
    </div>
  </div>
</div>
<!-- Footer End -->

<!-- BTN WHATSAPP -->
<a href="https://wa.me/34689024410?text=Hola%20me%20gustaría%20saber%20más%20sobre%20sus%20servicios"
  class="whatsapp-button" target="_blank" title="Chat en WhatsApp">
  <i class="fab fa-whatsapp"></i>
</a>

<!-- Back to Top -->
<a href="#" class="btn btn-primary px-3 back-to-top"><i class="fa fa-angle-double-up"></i></a>

<div id="cookie-consent" class="cookie-consent">
  <div class="cookie-message">
    <p>{{'app_id_32' | translate}}
      <a href="/cookies" id="learn-moreCookies">{{'app_id_33' | translate}}</a>.
    </p>
    <button id="accept-cookies">{{'app_id_34' | translate}}</button>
  </div>
</div>