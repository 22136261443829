import { Component, inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { RouterOutlet } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-root',
  standalone: true,
  imports: [HomeComponent,
    RouterOutlet,
    FormsModule,
    TranslateModule ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  translate: TranslateService = inject(TranslateService);
  platformId = inject(PLATFORM_ID); // Inyectar el identificador de plataforma

  constructor() {
    if (isPlatformBrowser(this.platformId)) {
      // Solo se ejecuta en el navegador
      const savedLang = localStorage.getItem('lang');
      if (savedLang) {
        this.translate.use(savedLang);
      } else {
        this.translate.use('es'); // Idioma por defecto
      }
    }
  }

  translateText(lang: string) {
    this.translate.use(lang);
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('lang', lang); // Guardar idioma solo si estamos en el navegador
    }
    location.reload();
  }
}