<!-- Page Header Start -->
<div class="container-fluid bg-appointment headerPageTest1">
    <div class="container">
        <div class="d-flex flex-column align-items-center justify-content-center" style="min-height: 400px">
            <h3 class="display-3 text-white text-uppercase">Blog</h3>
            <div class="d-inline-flex text-white">
                <p class="m-0 text-uppercase"><a class="text-white" href="/">Inicio</a></p>
                <i class="fa fa-angle-double-right pt-1 px-3"></i>
                <p class="m-0 text-uppercase">Blog</p>
            </div>
        </div>
    </div>
</div>
<!-- Page Header End -->

<div class="container-fluid py-5">
    <div class="container py-5">
        <div class="text-start pb-2">
            <h6 class="text-uppercase">Blog</h6>
            <h1 class="mb-4">Descubre Nuestras Últimas Novedades</h1>
        </div>
        <div class="row">
        </div>
    </div>
</div>