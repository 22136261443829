<!-- Page Header Start -->
<div class="container-fluid bg-contactPhoto1 headerPageTest1">
    <div class="container">
      <div class="d-flex flex-column align-items-center justify-content-center" style="min-height: 400px">
        <h3 class="display-3 text-white text-uppercase">Contacto</h3>
        <div class="d-inline-flex text-white">
          <p class="m-0 text-uppercase"><a class="text-white" href="/">Inicio</a></p>
          <i class="fa fa-angle-double-right pt-1 px-3"></i>
          <p class="m-0 text-uppercase">Contacto</p>
        </div>
      </div>
    </div>
  </div>
  <!-- Page Header End -->
  
  <!-- Contact Start -->
  <div class="container-fluid py-5">
    <div class="container py-5">
      <div class="text-start pb-2">
        <h6 class="text-uppercase">Contactar</h6>
        <h1 class="mb-4">Envíanos un Mensaje</h1>
      </div>
      <div class="row">
        <div class="col-lg-6 mb-5 mb-lg-0">
          <div class="contact-form">
            <div id="success"></div>
            <form name="sentMessage" id="contactForm" (ngSubmit)="onSubmit()" #contactForm="ngForm">
              <div class="form-row">
                <div class="col-sm-6 control-group">
                  <input type="text" class="form-control p-4" id="name" [(ngModel)]="contactData.name" name="name" placeholder="Nombre" required />
                </div>
                <div class="col-sm-6 control-group">
                  <input type="email" class="form-control p-4" id="email" [(ngModel)]="contactData.email" name="email" placeholder="Correo electrónico" required />
                </div>
              </div>
              <div class="control-group">
                <input type="text" class="form-control p-4" id="subject" [(ngModel)]="contactData.subject" name="subject" placeholder="Asunto" required />
              </div>
              <div class="control-group">
                <textarea class="form-control p-4" rows="6" id="message" [(ngModel)]="contactData.message" name="message" placeholder="Mensaje" required></textarea>
              </div>
              <div>
                <button type="submit" class="btn btn-primary btn-block" [disabled]="!contactForm.valid">Enviar Mensaje</button>
              </div>
            </form>
          </div>
        </div>
        <div class="col-lg-6" style="min-height: 400px;">
          <div class="position-relative h-100 rounded overflow-hidden">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2985.890185555167!2d2.104580376471077!3d41.549974885893796!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a49502f2537b89%3A0x61be093bc43158e1!2sCarrer%20de%20l&#39;Escola%20Pia%2C%207%2C%2008201%20Sabadell%2C%20Barcelona!5e0!3m2!1ses!2ses!4v1729701653971!5m2!1ses!2ses"
              width="600" height="450" style="border:0;width:100%;" allowfullscreen="" loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Contact End -->
  