import { Component, inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css'
})
export class HomeComponent {
  translate: TranslateService = inject(TranslateService);
  platformId = inject(PLATFORM_ID); // Inyectar el identificador de plataforma

  constructor() {
    if (isPlatformBrowser(this.platformId)) {
      // Solo se ejecuta en el navegador
      const savedLang = localStorage.getItem('lang');
      if (savedLang) {
        this.translate.use(savedLang);
      } else {
        this.translate.use('en'); // Idioma por defecto
      }
    }
  }

}
