<section id="inicio">
    <div class="container-fluid p-0 mb-5 pb-5">
        <div id="header-carousel" class="carousel slide carousel-fade" data-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item position-relative active" style="height: 100vh; min-height: 400px;">
                    <img class="position-absolute w-100 h-100" src="assets/img/carousel-1RR.png"
                        style="object-fit: cover;">
                    <div class="carousel-caption d-flex flex-column align-items-center justify-content-center">
                        <div class="p-3" style="max-width: 900px;transform: translateY(100px);">
                            <h4 class="text-white text-uppercase mb-4" style="letter-spacing: 3px;">{{'home_id_1' | translate}}
                            </h4>
                            <h3 class="display-2 text-white mb-4">{{'home_id_2' | translate}}</h3>
                            <a class="btn btn-primary py-3 px-5 mt-2"
                                href="https://outlook.office.com/bookwithme/user/c123b1fba909492b860692f49fe1d0a8@icasbd.org?anonymous&ep=signature"
                                target="_blank">{{'home_id_3' | translate}}</a>
                        </div>
                    </div>
                </div>
                <div class="carousel-item position-relative" style="height: 100vh; min-height: 400px;">
                    <img class="position-absolute w-100 h-100" src="assets/img/carousel-3RR.png"
                        style="object-fit: cover;">
                    <div class="carousel-caption d-flex flex-column align-items-center justify-content-center">
                        <div class="p-3" style="max-width: 900px;transform: translateY(100px);">
                            <h4 class="text-white text-uppercase mb-4" style="letter-spacing: 3px;">{{'home_id_4' | translate}}
                            </h4>
                            <h3 class="display-2 text-white mb-4">{{'home_id_5' | translate}}</h3>
                            <a class="btn btn-primary py-3 px-5 mt-2" href="tel:+34689024410">{{'home_id_6' | translate}}</a>
                        </div>
                    </div>
                </div>
            </div>
            <a class="carousel-control-prev" href="#header-carousel" data-slide="prev">
                <div class="btn btn-lg btn-secondary btn-lg-square">
                    <span class="carousel-control-prev-icon"></span>
                </div>
            </a>
            <a class="carousel-control-next" href="#header-carousel" data-slide="next">
                <div class="btn btn-lg btn-secondary btn-lg-square">
                    <span class="carousel-control-next-icon"></span>
                </div>
            </a>
        </div>
    </div>
</section>
<section id="firma">
    <div class="container-fluid py-5">
        <div class="container py-5">
            <div class="row">
                <div class="col-lg-5">
                    <img class="img-fluid rounded" src="assets/img/toniImg1RR.png" alt="">
                </div>
                <div class="col-lg-7 mt-4 mt-lg-0">
                    <h2 class="position-relative text-center bg-white text-primary rounded d-none d-lg-block"
                        style="width: 350px; margin-left: -205px; opacity: 0 !important"></h2>
                    <h6 class="text-uppercase">{{'home_id_7' | translate}}</h6>
                    <h1 class="mb-2">{{'home_id_8' | translate}}</h1>
                    <p [innerHTML]="'home_id_9' | translate">En <strong>Rodríguez Rovira Advocats</strong> nos ocupamos de proporcionar a los clientes un
                        <strong>servicio legal</strong> adaptado a sus <strong>necesidades</strong> y basado en la
                        <strong>eficacia</strong>, <strong>profesionalidad</strong> y <strong>cercanía</strong>.
                    </p>

                    <p [innerHTML]="'home_id_10' | translate">Sabemos lo importante que es tener un <strong>abogado de confianza</strong> que se preocupe de
                        llevar
                        tu <strong>proceso legal</strong> de la mejor manera posible. En nuestro despacho nos aseguramos
                        de
                        <strong>escuchar tus necesidades</strong> desde el primer momento y brindarte las
                        <strong>soluciones
                            más adecuadas</strong>, ofreciendo un <strong>asesoramiento transparente</strong>,
                        <strong>claro</strong> y <strong>personalizado</strong>. <strong>Confía en nosotros</strong>
                        para
                        acompañarte y guiarte con seguridad en cada paso que sigamos.
                    </p>

                    <a class="btn btn-primary mt-1" href="/firma">{{'home_id_11' | translate}}</a>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="servicios">
    <div class="container-fluid py-5">
        <div class="container py-5">
            <div class="row">
                <div class="col">
                    <!-- Títulos alineados a la izquierda -->
                    <h6 class="text-uppercase mb-2">{{'home_id_12' | translate}}</h6>
                    <div class="row">
                        <div class="col">
                            <h1 class="mb-2">{{'home_id_13' | translate}}</h1>

                        </div>
                        <div class="col-auto justify-content-end">
                            <a href="/servicios" class="btn btn-primary">{{'home_id_14' | translate}}</a>
                        </div>
                        <!-- Texto y botón alineados horizontalmente a la derecha -->


                    </div>
                    <p class="mb-2">
                        {{'home_id_15' | translate}}
                    </p>
                </div>
                <div class="container">
                    <div class="row">
                        <!-- Cuadrado 1 -->
                        <div class="col-md-6 col-lg-4 mb-4">
                            <div class="image-box">
                                <div class="background-image"
                                    style="background-image: url('assets/img/medium-shot-couple-talking-real-estate-agent.jpg');">
                                </div>
                                <div class="image-box-overlay"></div>
                                <div class="content w-100 p-4">
                                    <h2 class="text-white">{{'home_id_16' | translate}}</h2>
                                    <p class="text-center">{{'home_id_17' | translate}}</p>
                                </div>
                                <a href="/servicios/inmobiliario" class="view-more">{{'home_id_18' | translate}}</a>
                            </div>
                        </div>

                        
                        <!-- Cuadrado 2 -->
                        <div class="col-md-6 col-lg-4 mb-4">
                            <div class="image-box">
                                <div class="background-image"
                                    style="background-image: url('assets/img/mother-son-upset-father.jpg');">
                                </div>
                                <div class="image-box-overlay"></div>
                                <div class="content w-100 p-4">
                                    <h2 class="text-white">{{'home_id_19' | translate}}</h2>
                                    <p class="text-center">{{'home_id_20' | translate}}</p>
                                </div>
                                <a href="/servicios/familia" class="view-more">{{'home_id_21' | translate}}</a>
                            </div>
                        </div>
                        
                        <!-- Cuadrado 3 -->

                        <div class="col-md-6 col-lg-4 mb-4">
                            <div class="image-box">
                                <div class="background-image"
                                    style="background-image: url('assets/img/mid-adult-businessman-holding-his-belongings-feeling-sad-because-he-lost-his-job.jpg');">
                                </div>
                                <div class="image-box-overlay"></div>
                                <div class="content w-100 p-4">
                                    <h2 class="text-white">{{'home_id_22' | translate}}</h2>
                                    <p class="text-center">{{'home_id_23' | translate}}</p>
                                </div>
                                <a href="/servicios/despidos" class="view-more">{{'home_id_24' | translate}}</a>
                            </div>
                        </div>
                        
                        <!-- Cuadrado 4 -->
                        <div class="col-md-6 col-lg-4 mb-4">
                            <div class="image-box">
                                <div class="background-image"
                                    style="background-image: url('assets/img/26706.jpg');">
                                </div>
                                <div class="image-box-overlay"></div>
                                <div class="content w-100 p-4">
                                    <h2 class="text-white">{{'home_id_25' | translate}}</h2>
                                    <p class="text-center">{{'home_id_26' | translate}}</p>
                                </div>
                                <a href="/servicios/seguridad-social" class="view-more">{{'home_id_27' | translate}}</a>
                            </div>
                        </div>
                        <!-- Cuadrado 5 -->
                        <div class="col-md-6 col-lg-4 mb-4">
                            <div class="image-box">
                                <div class="background-image"
                                    style="background-image: url('assets/img/road-accident-with-smashed-cars.jpg');">
                                </div>
                                <div class="image-box-overlay"></div>
                                <div class="content w-100 p-4">
                                    <h2 class="text-white">{{'home_id_28' | translate}}</h2>
                                    <p class="text-center">{{'home_id_29' | translate}}</p>
                                </div>
                                <a href="/servicios/accidentes-trafico" class="view-more">{{'home_id_30' | translate}}</a>
                            </div>
                        </div>
                        <!-- Cuadrado 6 -->
                        
                        <div class="col-md-6 col-lg-4 mb-4">
                            <div class="image-box">
                                <div class="background-image"
                                    style="background-image: url('assets/img/2149601366.jpg');">
                                </div>
                                <div class="image-box-overlay"></div>
                                <div class="content w-100 p-4">
                                    <h2 class="text-white">{{'home_id_31' | translate}}</h2>
                                    <p class="text-center">{{'home_id_32' | translate}}
                                    </p>
                                </div>
                                <a href="/servicios/nacionalidad-extranjeria" class="view-more">{{'home_id_33' | translate}}</a>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>
</section>


<div class="container-fluid py-5">
    <div class="container py-5">
        <div class="rounded">
            <h6 class="text-uppercase text-center">{{'home_id_34' | translate}}</h6>
            <h1 class="mb-4 text-center">{{'home_id_35' | translate}}</h1>
            <div style="min-height: 450px">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2985.890185555167!2d2.104580376471077!3d41.549974885893796!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a49502f2537b89%3A0x61be093bc43158e1!2sCarrer%20de%20l&#39;Escola%20Pia%2C%207%2C%2008201%20Sabadell%2C%20Barcelona!5e0!3m2!1ses!2ses!4v1729701653971!5m2!1ses!2ses"
                    width="600" height="450" style="border:0;width:100%;" allowfullscreen="" loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"></iframe>

            </div>
        </div>
    </div>
</div>


<div class="container-fluid py-5">
    <div class="container py-5">
        <div class="row">
            <div class="col-lg-6" style="min-height: 500px;">
                <div class="position-relative h-100 rounded overflow-hidden">
                    <img class="position-absolute w-100 h-100" src="assets/img/consultaOnline1.png" style="object-fit: cover;">
                </div>
            </div>
            <div class="col-lg-6 pt-5 pb-lg-5 ">
                <div class="feature-text bg-white rounded p-lg-5">
                    <h6 class="text-uppercase">{{'home_id_36' | translate}}</h6>
                    <h2 class="mb-4">{{'home_id_37' | translate}}</h2>
                    <div class="d-flex mb-4">
                        <div class="btn-primary btn-lg-square px-3" style="border-radius: 50px;">
                            <h5 class="text-secondary m-0">1</h5>
                        </div>
                        <div class="ml-4">
                            <h5>{{'home_id_38' | translate}}</h5>
                            <p class="m-0">{{'home_id_39' | translate}}</p>
                        </div>
                    </div>
                    <div class="d-flex mb-4">
                        <div class="btn-primary btn-lg-square px-3" style="border-radius: 50px;">
                            <h5 class="text-secondary m-0">2</h5>
                        </div>
                        <div class="ml-4">
                            <h5>{{'home_id_40' | translate}}</h5>
                            <p class="m-0">{{'home_id_41' | translate}}</p>
                        </div>
                    </div>
                    <div class="d-flex mb-4">
                        <div class="btn-primary btn-lg-square px-3" style="border-radius: 50px;">
                            <h5 class="text-secondary m-0">3</h5>
                        </div>
                        <div class="ml-4">
                            <h5>{{'home_id_42' | translate}}</h5>
                            <p class="m-0">{{'home_id_43' | translate}}</p>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="btn-primary btn-lg-square px-3" style="border-radius: 50px;">
                            <h5 class="text-secondary m-0">4</h5>
                        </div>
                        <div class="ml-4">
                            <h5>{{'home_id_44' | translate}}</h5>
                            <p class="m-0">{{'home_id_45' | translate}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="container-fluid py-5">
    <div class="container py-5">
        <div class="bg-action rounded" style="height: 500px;">
            <div class="row h-100 align-items-center justify-content-center">
                <div class="col-lg-7 text-center">
                    <h1 class="text-white mb-4">{{'home_id_46' | translate}}</h1>
                    <a class="btn btn-primary py-3 px-5 mt-2" href="tel:+34689024410">{{'home_id_47' | translate}}</a>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="container-fluid py-5">
    <div class="container py-5">
        <div class="text-center pb-5">
            <h6 class="text-uppercase">{{'home_id_48' | translate}}</h6>
            <h1 class="mb-5">{{'home_id_49' | translate}}</h1>
        </div>

        <div class="elfsight-app-ab3584ed-4b99-428a-bbd7-cd635f950a17" data-elfsight-app-lazy></div>

    </div>
</div>